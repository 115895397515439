import Button from 'components/common/form/Button'

const Dashboard = () => {
  const handleEmergency = () => {
    const link = 'https://wiki.migros.net/display/GOOD/Emergency+Shutdown'

    window.open(link)
  }

  return (
    <>
      <div
        className={`flex align-end justify-end gap-4 p-4 mb-6 sticky top-0 bg-white ${
          !top ? 'shadow-header' : ''
        }`}
      >
        <Button type="PRIMARY" onClick={handleEmergency}>
          Emergency Shutdown!
        </Button>
      </div>
    </>
  )
}

export default Dashboard

import React, { useEffect, useState } from 'react'
import type { NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { Trans, useTranslation } from 'next-i18next'
import Dashboard from './dashboard'

type Locale = {
  locale: string
}

export async function getStaticProps({ locale }: Locale) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

const Home: NextPage = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Dashboard />
    </div>
  )
}

export default Home

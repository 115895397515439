type Props = {
  children: any
  onClick?: () => void
  type?: 'PRIMARY' | 'SECONDARY'
  buttonType?: 'button' | 'submit' | 'reset'
  className?: string
  disabled?: boolean
  tooltip?: string
}

const Button = ({
  children,
  onClick,
  type = 'PRIMARY',
  buttonType = 'button',
  className,
  disabled = false,
  tooltip,
}: Props) => {
  if (type === 'SECONDARY') {
    return (
      <button
        disabled={disabled}
        type={buttonType}
        onClick={onClick}
        className={`text-black border-2 bg-white hover:bg-violet-100 focus:ring-4 focus:outline-none focus:ring-violet-300 font-medium rounded-lg text-sm px-8 py-1 text-center disabled:opacity-50 disabled:cursor-not-allowed ${className}`}
        title={tooltip}
      >
        {children}
      </button>
    )
  } else {
    return (
      <button
        disabled={disabled}
        type={buttonType}
        onClick={onClick}
        className={`text-white bg-violet-700 hover:bg-violet-800 focus:ring-4 focus:outline-none focus:ring-violet-300 font-medium rounded-lg text-sm px-8 py-1 text-center disabled:opacity-50 disabled:cursor-not-allowed ${className}`}
        title={tooltip}
      >
        {children}
      </button>
    )
  }
}

export default Button
